<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="400" min-height="400" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
          <el-form-item prop="name" label="客户名称">
            <el-input v-model="data.name" placeholder="输入客户名称" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="mobile" label="手机号">
            <el-input v-model="data.mobile" placeholder="输入手机号" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="address" label="地址">
            <el-input v-model="data.address" placeholder="输入地址" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="person" label="负责人">
            <el-input v-model="data.person" placeholder="输入负责人" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="note" label="备注">
            <el-input v-model="data.note" placeholder="输入备注" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value) {
        if (this.$validate.validateMobile(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的11位手机号'))
        }
      } else {
        callback()
      }
    }
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        name: [{ required: true, trigger: 'blur', message: '输入客户名称' }],
        mobile: [{ required: false, trigger: 'blur', validator: validatePhone }],
        // address: [{ required: true, trigger: 'blur', message: '输入地址' }],
        // person: [{ required: true, trigger: 'blur', message: '输入负责人' }],
      },
      loading: false,
      isDisable: true,
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = this.$utils.clone(data.data, true)
      this.name = data.name
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = this.data
          this.$axios
            .post('/order/customer', params)
            .then((res) => {
              if (res) {
                console.log(this.$utils.isUndefined(this.data.id))
                if (this.$utils.isUndefined(this.data.id)){
                  this.$message.success('新增成功')
                }
                else {
                  this.$message.success('编辑成功')
                }
                this.show = false
                this.$emit('init')
              }
              this.loading = false
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>